import {React, useEffect, useState, useContext } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import useWindowSize from '../hooks/useWindowSize'
import { connect } from 'react-redux'
import { setUser } from '../../actions'
import {Context} from "../lang/Wrapper"
import { useIntl, FormattedMessage } from 'react-intl'
import Car from '../../assets/images/car-icon.svg'
//import Vegas from '../../assets/images/Vegas-l.png'
//import Casco from '../../assets/images/casco-l.png'
import Casco from '../../assets/images/awards_casco.png'
import Reloj from '../../assets/images/awards_reloj.png'
import Maranello from '../../assets/images/awards_ferrari.png'
//import MiniCasco from '../../assets/images/casco-mini-l.png'
import MiniCasco from '../../assets/images/awards_casco_miniatura.png'
//import Merch from '../../assets/images/merch-l.png'
import Merch from '../../assets/images/awards_merchan.png'
import Merch2 from '../../assets/images/awards_pack.png'
import Gafas from '../../assets/images/awards_gafas.png'
//import Commitment from '../../assets/images/commitment.png'
import LogoLapZero from '../../assets/images/logo-s-lap-zero.svg'
import Arrow from '../../assets/images/select-arrow.svg'
import Slider from "react-slick"
import CookiePanel from './CookiePanel'
import { AnimatePresence} from "framer-motion"
import {Countries} from '../Login/Countries'
import ModalF1 from '../common/ModalF1'
import ModalSedes from './ModalSedes'

const Landing = (props) => {   
    const closed = true;
    const size = useWindowSize();
    const tablet = 768;
    //const h = getHeight(0, 100);
    let intl = useIntl();   
    let navigate = useNavigate();
    var search = useLocation().search; 
    const context = useContext(Context); 
    const [showCookiePanel, setShowCookiePanel] = useState(false);
    const [showNoMoreMails, setShowNoMoreMails] = useState(false);
    const [showClosed, setShowClosed] = useState(false);
    const [showSedes, setShowSedes] = useState(false);
    const SlickNextArrow = ({ currentSlide, slideCount, ...props }) => (
        <img src={Arrow}
          {...props}
          className={
            "slick-next slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
          }
          aria-hidden="true"
          aria-disabled={currentSlide === slideCount - 1 ? true : false}
          type="button"
        >
        </img>
    );
    const SlickPrevArrow = ({ currentSlide, slideCount, ...props }) => (
        <img src={Arrow}
          {...props}
          className={
            "slick-prev slick-arrow" +
            (currentSlide === slideCount - 1 ? " slick-disabled" : "")
          }
          aria-hidden="true"
          aria-disabled={currentSlide === slideCount - 1 ? true : false}
          type="button"
        >
        </img>
      );
    var settings = {
        dots: true,
        customPaging: function(i) {
        return (
            <div className={"dot "+ i}></div>
        );
        },
        arrows: true,
        prevArrow: <SlickPrevArrow/>,//<img src={Arrow} />,
        nextArrow: <SlickNextArrow/>,//></SlickArrowRight><img src={Arrow} />,
        infinite: false,
        centerMode: true,
        centerPadding: "60px",
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    var instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 16000,
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('JWT') }
    });

    const goToRemove = (a, b) =>{
        if (a!=='' && b!='' && a!==null && b!=null){
            localStorage.setItem("A", a);
            localStorage.setItem("B", b);
            navigate("/dashboard");
        }
    }

    /*const goToWelcome = (code) => {
        if (code !== '' && code !== null){      
            localStorage.setItem("F1-TEAM", code);            
            navigate("/welcome");        
        }  
    }*/
    const goToPage = (page) => {
        if (page !== null && page !== "" && page!== "cookie-panel"){
            ///?page=teams&utm_campaign=F1&utm_medium=DMA&utm_source=DIR&utm_content=ORG_EN
            //utm_campaign=F1&utm_medium=DMA&utm_source=DIR&utm_content=ORG_EN
            const c = new URLSearchParams(search).get('utm_campaign');
            const m = new URLSearchParams(search).get('utm_medium');
            const s = new URLSearchParams(search).get('utm_source');
            const c2 = new URLSearchParams(search).get('utm_content');
            if (c!==null){
                navigate({ pathname:"/"+page, search: 'utm_campaign='+c+'&utm_medium='+m+'&utm_source='+s+'&utm_content='+c2 });
            } else {
                navigate("/"+page);
            }
        }
        if (page ==="cookie-panel"){
            setShowCookiePanel(true);
        }
    }

    const getCountry = () => {
        var l = navigator.language;
        var parts = l.split("-");
        var c = parts[parts.length-1].toUpperCase();
        var found = "Other";
        Countries.getCountries().map((country) => {
            if (country.alpha2Code.toUpperCase() === c)
            found = country.name
        });
        //console.log(found);
        return { name: found, code:c, lang: l};
    }

    const checkNoMail= () => {
        const n = new URLSearchParams(search).get('n');
        const h = new URLSearchParams(search).get('h');
        if (n!==null && h!= null){
            instance.post('/nomoremail', {nickname:n, hash:h})
            .then(
                ()=>{
                    setShowNoMoreMails(true)
                    //console.log("No more mail success");
                }
            )
            .catch( ()=>{
                //console.log("No more mail error");
            })
        }
    }

    useEffect(() => {
        props.setPage('landing');
        window.dataLayer.push({
            'user_country' : getCountry()
        });
        checkNoMail();
        //if (window.location.hostname.toLowerCase() === "localhost"){
        /*if (window.location.hostname.toLowerCase()==="www.santanderlapzero.com"){
            navigate("/comingsoon");
        }else{*/
            //h = getHeight(0, 100);
            const page = new URLSearchParams(search).get('page');
            //const code = new URLSearchParams(search).get('team');
            const a = new URLSearchParams(search).get('a');
            const b = new URLSearchParams(search).get('b');
            /*if (code !== '' && code !== null){
                localStorage.setItem("F1-TEAM", code);
                navigate("/welcome");
            }*/   
            if (localStorage.getItem("JWT") !== null){
                instance.get('/api/user')
                .then( (response) =>{
                    window.dataLayer.push({
                        'userId' : response.data.user.id
                    }); 
                    props.setUser({"id": response.data.user});
                    context.selectLanguage(response.data.user.idioma);
                    //goToWelcome(code);
                    goToRemove(a,b);
                    goToPage(page);
                })
                .catch( () =>{      
                    //goToWelcome(code);
                    goToRemove(a,b);
                    goToPage(page);
                })        
            }else{
                //goToWelcome(code);
                goToRemove(a,b);
                goToPage(page);
            }
        /*}*/
    }, []); 
    
    //Close CookiePanel on outer click
   useEffect(() => {
    const checkCloseSubmenu = e => {
      var closest = e.target.closest(".modalWrapper");         
      if ( showCookiePanel && closest === null) {
        setShowCookiePanel(false);        
        window.location = window.location.pathname;
      }
    }  
    document.addEventListener("mousedown", checkCloseSubmenu)  
    return () => {        
      document.removeEventListener("mousedown", checkCloseSubmenu)
    }
  }, [showCookiePanel])

    const getHeight = (n, perc) => {
        if (size.width < tablet){            
            if (n===0 && perc > 0){
                return {minHeight: ( size.height*perc /100)+"px"};
            } else  {
                return {minHeight: (( size.height - n)*perc /100)+"px"};
            }            
        }
        return null;
    }
      

    return <main className="landing">
        {showSedes?<ModalSedes close={()=>setShowSedes(false)} buttonText={intl.formatMessage({id:"app.modalspecial.close"})} />:null}
        <AnimatePresence>
            {showCookiePanel?<CookiePanel close={ ()=>{ setShowCookiePanel(false); window.location = window.location.pathname; } }/>:null}
            {showNoMoreMails?<ModalF1 text={intl.formatMessage({ id: 'app.modal.nomoremails' })} buttonText={intl.formatMessage({ id: 'app.modalspecial.close' })} close={()=>{setShowNoMoreMails(false)}}></ModalF1>:null}
            {showClosed?<ModalF1 text={intl.formatMessage({id: "app.modal.close2024.text"})} text2={ intl.formatMessage({id: "app.landing.title.close"})} buttonText={intl.formatMessage({ id: 'app.modalspecial.close' })} close={()=>{setShowClosed(false)}}></ModalF1>:null}
        </AnimatePresence>
        <section className="landing-header"  style={getHeight(0, 100) }>
            <h1 className={ context.locale.toLowerCase() }>
                <span><FormattedMessage id = 'app.landing.title' values={{
                  br: <br />
                }} /></span>   
                <span><FormattedMessage id = 'app.landing.title2' values={{
                  br: <br />
                }} /></span>     
            </h1>
            {/* <p>{intl.formatMessage({ id: 'app.landing.answer' })}</p> */}
            <div className="flex">
                <Link id="join-now" onClick={ (event)=>{ setShowClosed(true); event.preventDefault();}} to="/login" className="btn">{intl.formatMessage({ id: 'app.landing.join' })}</Link>
                <img className="logo" src={LogoLapZero} alt="" />
            </div>            
        </section>        
        <section className="landing-video participate"> 
            <div>
                <h2>{intl.formatMessage({ id: 'app.landing.howto' })}</h2>
                <p>{intl.formatMessage({ id: 'app.landing.easy' })}</p>               
            </div>
            <div className="landing-box">
                <div className="flex">
                    <img src={Car}/>
                    <h2>{intl.formatMessage({ id: 'app.landing.want' })}</h2>
                </div>
                <Link to="/demo" className={"btn " + context.locale.toLowerCase() }>{intl.formatMessage({ id: 'app.landing.join2' })}</Link>
            </div>
        </section>
        <section className="landing-video types">  
            <h2>{intl.formatMessage({ id: 'app.landing.types' })} <br/>{intl.formatMessage({ id: 'app.landing.are' })}</h2>
            <div className="closed">
                <div className="box season">
                    <div className="box-wrapper">
                        <h2>{intl.formatMessage({ id: 'app.landing.champ' })}</h2>
                        <p>{intl.formatMessage({ id: 'app.landing.ready' })}</p>
                    </div>
                </div>
                {!closed && <div className="box gp">
                    <div className="box-wrapper">
                        <h2>{intl.formatMessage({ id: 'app.landing.gp' })}</h2>
                        <p>{intl.formatMessage({ id: 'app.landing.areyou' })}</p>
                    </div>
                </div>}
                <div className="box team">
                    <div className="box-wrapper">
                        <h2>{intl.formatMessage({ id: 'app.landing.team' })}</h2>
                        <p>{intl.formatMessage({ id: 'app.landing.play' })}</p>
                    </div>
                </div>
            </div>
        </section>
        { !closed &&
        <section className="landing-video awards"> 
            <div className="padding">
                <h2>{intl.formatMessage({ id: 'app.landing.awards' })}</h2>
                <p>{intl.formatMessage({ id: 'app.landing.trivia' })}</p>
                <Link to="/my-prizes" className="btn desktop">{intl.formatMessage({ id: 'app.landing.know' })}</Link>
            </div>
						
            <div className="slick-wrapper">
							<Slider {...settings}>
								<div className="item"><img src={Casco} alt="casco" /></div>
								<div className="item"><img src={Reloj} alt="reloj" /></div>
								<div className="item"><img src={Maranello} alt="maranello" /></div>
								<div className="item"><img src={MiniCasco} alt="minicasco" /></div>
								<div className="item"><img src={Gafas} alt="gafas" /></div>
								<div className="item"><img src={Merch} alt="merchandising" /></div>
								<div className="item"><img src={Merch2} alt="merchandising" /></div>
							</Slider>
						</div>

            <div className="padding button">
                <Link to="/my-prizes" className="btn">{intl.formatMessage({ id: 'app.landing.know' })}</Link>
            </div>
        </section>        
        }
        {closed &&
            <section className="landing-data">
                <p>{intl.formatMessage({ id: "app.landing.data.close" })} <a href="#" onClick={(event)=>{ setShowSedes(true); event.preventDefault(); }} >{intl.formatMessage({ id: "app.landing.data.close.2" })}</a>.</p>
            </section>
        }
    </main>
}
const mapStateToprops = (state)=>{        
    return {...state};  
}
export default connect(mapStateToprops,{setUser:setUser})(Landing)